import React, { useState } from "react";

import Layout from "../components/layout";
import CtaSection from "../components/CtaSection";

import audienceGrowthImage from "../images/audience-growth.svg";
import messengerImage from "../images/messenger.svg";
import subscribeFormsImage from "../images/subscribe-forms.svg";
import videoThumbnailImage from "../images/sidemail-email-marketing-video-thumbnail.png";

const ListItem = ({ children }) => {
	return (
		<li>
			<span className="fa-li">
				<i className="fas fa-check" />
			</span>
			{children}
		</li>
	);
};

const EmailMarketingPage = () => {
	const [isVideoVisible, setVideoVisibility] = useState(false);

	return (
		<Layout>
			<div>
				<section className="aboveFold">
					<div className="container">
						<h1>Email marketing made easy</h1>

						<p className="subtitle">
							Sidemail makes managing your mailing list dead simple. Don't pay
							for the number of subscribers you have, only pay for the emails
							you send. Create your campaigns with no-code editor or use rich
							text editor for some quick news.
						</p>

						<a
							className="button button--bright"
							href="https://client.sidemail.io/register">
							Get started for FREE
						</a>

						<div className="finePrint" style={{ marginBottom: 100 }}>
							No credit card required. No commitment.
						</div>

						<div style={{ maxWidth: 833, margin: "0 auto" }}>
							<div className="playerContainer" id="video">
								<iframe
									title="Watch how Sidemail email marketing works"
									width="100%"
									height="100%"
									src="https://www.youtube.com/embed/xkzxvxvFyIM?autoplay=1&modestbranding=1&rel=0"
									frameBorder="0"
									allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
									allowFullScreen
									style={{ display: !isVideoVisible && "none" }}
								/>

								<div
									className="player"
									onClick={() => {
										setVideoVisibility(true);
									}}
									style={{ display: isVideoVisible && "none" }}>
									<div className="player-controls">
										<div className="player-playhead" />
										<div className="player-title">Watch how it works</div>
									</div>

									<img
										className="player-thumbnail"
										src={videoThumbnailImage}
										alt="Video thumbnail"
									/>
								</div>
							</div>
						</div>
					</div>
				</section>

				<section className="container">
					<div className="twoColumns twoColumns--reversed">
						<div>
							<img src={messengerImage} alt="Sidemail Messenger" />
						</div>

						<div className="twoColumns-text">
							<h2>Keep your users in the loop with Messenger</h2>

							<p className="text">
								Let your users know about what you're working on. Use simple
								no-code email builder for emails like product updates or rich
								text editor for quick news.
							</p>

							<div className="set">
								<i className="fas fa-check" />
								<div className="set-content">
									<h4>Scheduled delivery</h4>
									<p className="text">
										They say there's never a perfect time. That's probably
										because they don't schedule delivery of their emails for the
										correct hour and based on recipients timezone. You will.
									</p>
								</div>
							</div>
							<div className="set">
								<i className="fas fa-check" />
								<div className="set-content">
									<h4>Detailed insights</h4>
									<p className="text">
										Get all the insights you need. Deliveries and opens? Check.
										Bounces and complaints? That too. Wondering if Bob got his
										discount? Visit the sending history.
									</p>
								</div>
							</div>
						</div>
					</div>
				</section>

				<section className="container">
					<div className="twoColumns">
						<div className="twoColumns-text">
							<h2>Build the mailing list and grow your audience</h2>

							<p className="text">
								We all know the advice. Build your audience first. But, you
								don't know where to start and when you finally get to it you
								realize how time-consuming is getting set up. If you want things
								to move quickly, then you've come to the right place.
							</p>

							<div className="set">
								<i className="fas fa-check" />
								<div className="set-content">
									<h4>Painless migration</h4>
									<p className="text">
										Say goodbye to your old clunky software and get up and
										running in a few clicks with simple CVS migration.
									</p>
								</div>
							</div>

							<div className="set">
								<i className="fas fa-check" />
								<div className="set-content">
									<h4>Dead simple segmentation</h4>
									<p className="text">
										Organize contacts into groups. Did Bob subscribe via blog?
										Let's add him to blog group. Use more specific groups and
										take your segmentation further (eg.: "coding tutorial" and
										"blog post - how to send emails" groups).
									</p>
								</div>
							</div>
						</div>

						<div>
							<img src={audienceGrowthImage} style={{ marginBottom: 40 }} alt=""/>

							<div className="infoCard">
								<ul className="list fa-ul">
									<ListItem>Unlimited contacts and groups</ListItem>
									<ListItem>Subscriber's timezone detection</ListItem>
									<ListItem>Unsubscribe handling</ListItem>
									<ListItem>Custom contact properties</ListItem>
								</ul>
							</div>
						</div>
					</div>
				</section>

				<section className="container">
					<div className="twoColumns twoColumns--reversed">
						<div>
							<img src={subscribeFormsImage} alt="" />
						</div>

						<div className="twoColumns-text">
							<h2>Create subscribe forms with no-code editor</h2>

							<p className="text">
								Create simple subscribe forms in no-time with Sidemail Forms.
								You have total control over what data you collect. You can also
								set an automatic email response when someone subscribes, handy
								for a welcome email.{" "}
								<a
									target="_blank"
									rel="noopener noreferrer"
									href="https://hosted.sidemail.io/5d03578b2620ea00fc28a728">
									Subscribe form example
								</a>
							</p>

							<div className="set">
								<i className="fas fa-check" />
								<div className="set-content">
									<h4>Metrics you care about</h4>
									<p className="text">
										See visitor count (page views), a number of submits and
										conversion rate.
									</p>
								</div>
							</div>

							<div className="set">
								<i className="fas fa-check" />
								<div className="set-content">
									<h4>Subscriber's timezone</h4>
									<p className="text">
										To learn when it's the best time to send, we guess the
										subscriber's timezone.
									</p>
								</div>
							</div>

							<div className="set">
								<i className="fas fa-check" />
								<div className="set-content">
									<h4>More control with code</h4>
									<p className="text">
										Need more control? Submit the form data over AJAX (no server
										needed).
									</p>
								</div>
							</div>
						</div>
					</div>
				</section>

				<CtaSection />
			</div>
		</Layout>
	);
};

export default EmailMarketingPage;
